import * as React from "react"
import { useContext, useEffect, useState } from "react"
import PageWithoutHero from "../../../../components/PageWithoutHero"
import BuildEditor from "../../../../components/BuildSelector/BuildEditor"
import { auth } from "../../../../utils/firebase"
import { AuthContext } from "../../../../utils/context"
import { Link } from "gatsby"

const PreSelections = ({ id = '', location }) => {
  const [url, setUrl] = useState("")
  const {isAdmin, user} = useContext(AuthContext)

  useEffect(() => {
    if (location?.origin) {
      setUrl(`${location.origin}/accounts/${id}/build-selections/`)
    }

  }, [])

  return (
    <PageWithoutHero>
      {isAdmin &&
        <>
          <h2>Pre-Selections</h2>
          <p>Pre-select any selections already included in the clients contract. If the selection is
            an
            upgrade (costs money) this amount will become a credit on the users account.</p>
          <p>Client
            url: <br/><a href={url} target={"_blank"}>{url}</a>
          </p>
          <BuildEditor id={id.toUpperCase()} isPreSelecting={true}/>
        </>
      }
      {!user &&
        <p>Please <Link to={'/login'}>login</Link> to make pre-selections for this build.</p>
      }
    </PageWithoutHero>
  )
}

export default PreSelections
